let multipleColumnDropdown = function() {
	$('li.has-mega-menu').each(function(idx, val) {
        var set = 3, //Number of links to display in each column
            buffer = [],
            dropdown = $('.dropdown-menu', this),
            children = dropdown.find(".dropdown-item"),
            cols = Math.ceil(children.length / set),
            col_class = 'col-6 col-md-' + (cols >= 5 ? '2' : (cols == 4 ? '3' : (cols == 3 ? '4' : 'x'))),
            container_class = 'px-0 container container-' + (cols == 2 ? 'sm' : (cols == 3 ? 'md' : (cols == 4 ? 'lg' : (cols >= 5 ? 'xl' : 'x'))));
			
			children.remove();
			
        for (var i = 0; i < cols; i++) {
            buffer.push('<div class="' + col_class + '">');
            children.slice(i * set, (i + 1) * set).each(function() {
                buffer.push($(this).prop('outerHTML'));
            });
            buffer.push('</div>');
        }

        dropdown.append('<div class="' + container_class + '"><div class="row">' + buffer.join('\n') + '</div></div>');
    });
};
let checkNavbarCollapseIsShow = function() {
	return $('.collapse.navbar-collapse').hasClass("show") && $(window).width() < 991;
};
let bootstrapDropdownSmooth = function() {
	/* $('.dropdown').on('show.bs.dropdown', function(e) {
        $(this).find('.dropdown-toggle').first().addClass("show");
        console.log($(this).find('.dropdown-menu').first());
        console.log($(this).find('.dropdown-menu'));
        $(this).find('.dropdown-menu').first().slideDown(300);
    });

    $('.dropdown').on('hide.bs.dropdown', function(e) {
		$(this).find('.dropdown-toggle').first().removeClass("show");
        $(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
    }); */
};
let bootstrapNavbarToggle = function() {
	$('#navbarNav').on('show.bs.collapse', function () {
	  if($(".navbar-toggler[data-target='#navbarNav']").length > 0 && $(".navbar-toggler[data-target='#navbarNav'] > span > small").length > 0) $(".navbar-toggler[data-target='#navbarNav'] > span > small").html("CERRAR");
	})
	
	/* $('#navbarNav').on('hide.bs.collapse', function () {
	  if($(".navbar-toggler[data-target='#navbarNav']").length > 0 && $(".navbar-toggler[data-target='#navbarNav'] > span > small").length > 0) $(".navbar-toggler[data-target='#navbarNav'] > span > small").html("MENU");
	}) */
}
let hiddenAllDropdown = function() {
	if($('.dropdown-menu.show').length > 0)$('.dropdown.show [data-toggle="dropdown"]').dropdown('toggle');
};
let scrollDownUpNavbar = function() {
	var prevScrollpos = window.pageYOffset;
	window.onscroll = function() {
        // console.log('Scroll ', prevScrollpos);
		var currentScrollPos = window.pageYOffset;
		var header_nav_height = (document.querySelector("header.main-header").offsetHeight - 2);
		if (prevScrollpos > currentScrollPos) {
			if(currentScrollPos < header_nav_height * 2) document.querySelector("header.main-header").classList.remove("visibility-changed");
			if(document.querySelector("header.main-header.scroll-friendly")) document.querySelector("header.main-header.scroll-friendly").style.top = "0";
		} else if(currentScrollPos > header_nav_height * 2 && !checkNavbarCollapseIsShow()) {
			hiddenAllDropdown();
			if(currentScrollPos > header_nav_height * 2) document.querySelector("header.main-header").classList.add("visibility-changed");
            if(document.querySelector("header.main-header.scroll-friendly")) document.querySelector("header.main-header.scroll-friendly").style.top = (header_nav_height * -1) + "px";
		}
		prevScrollpos = currentScrollPos;
	}
};
let spaceContentNavbar = function() {
	var header_nav_height = document.querySelector("header.main-header").offsetHeight;
		$('header.main-header').next().css('margin-top', header_nav_height + "px");
		//document.querySelector("body").style.paddingTop = header_nav_height + "px";
};
jQuery(document).ready(function() {
	if(document.querySelectorAll("header.main-header.fixed-top").length > 0) {
        multipleColumnDropdown();
        bootstrapDropdownSmooth();
        spaceContentNavbar();
        scrollDownUpNavbar();
        bootstrapNavbarToggle();
    }
});
jQuery(window).resize(function(){
    if(document.querySelectorAll("header.main-header.fixed-top").length > 0) {
        spaceContentNavbar();
    }
});